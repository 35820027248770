import React from "react";

import Layout from "../components/layout-main";

const isBrowser = typeof window !== "undefined" && window;

// Check all redirect paths in lowercase
const REDIRECT_MAP = {
  "/for/funeral-homes": "/platform",
  "/ed-stillman": "/stories/ed-stillman/",
  "/pages/jordan-neustaeter": "/stories/jordan-neustaeter/",
  "/bancroft-wright": "/stories/bancroft-wright",
  "/ivy-simpson": "/stories/ivy-simpson",
  "/thomas-lee-morris": "/stories/thomas-lee-morris",
  "/liberatore-mainella": "/stories/liberato-mainella",
  "/liberato-mainella": "/stories/liberato-mainella",
  "/daveotte": "/stories/david-george-otte",
};
// Add trailing slashes
Object.keys(REDIRECT_MAP).forEach((key) => {
  REDIRECT_MAP[key + "/"] = REDIRECT_MAP[key];
});

const IS_SANDBOX =
  process.env.NODE_ENV === "development" ||
  (typeof window === "object" &&
    window.location.hostname.startsWith("sandbox"));
const BASE = IS_SANDBOX ? "qccf.afterword.com" : "app.afterword.com";

const HOSPITAL_SUBDOMAINS = [
  "rush",
  "ucla",
  "ucdavis",
  "localhost",
  "sbox-test",
];
export const LOCATION_SUBDOMAIN_MAP = {
  la: "Los Angeles",
  chicago: "Chicago",
  chi: "Chicago",
  nyc: "New York",
};
export const getLocationRedirectUrl = (subdomain) => {
  return `https://${BASE}/?l=${LOCATION_SUBDOMAIN_MAP[subdomain]}&utm_medium=shortlink`;
};

const NotFoundPage = ({ location }) => {
  if (!isBrowser) {
    return null;
  }
  let maybeRedirectPath = REDIRECT_MAP[location.pathname.toLowerCase()];
  if (maybeRedirectPath) {
    window.location = maybeRedirectPath;
    return;
  }
  let pathName =
    location.pathname[0] === "/"
      ? location.pathname.replace("/", "")
      : location.pathname;
  console.log(pathName);
  let locationRedirectPath = LOCATION_SUBDOMAIN_MAP[pathName.toLowerCase()];
  if (locationRedirectPath) {
    window.location = getLocationRedirectUrl(pathName.toLowerCase());
    return;
  }
  let hospitalRedirect =
    HOSPITAL_SUBDOMAINS.indexOf(pathName.toLowerCase()) > -1;
  if (hospitalRedirect) {
    window.location = `https://${BASE}/?ht=${pathName.toLowerCase()}&utm_medium=shortlink`;
    return;
  }

  return (
    isBrowser && (
      <Layout location={location} pageTitle="Not Found">
        <section>
          <div className="aw-content">
            <h1>This page doesn't exist</h1>
            <p>Sorry. You visited a page that doesn&#39;t exist.</p>
            <p>
              If you came here from our website, we'll note this visit and try
              to fix the broken link.
            </p>
            <p>We hope you find what you're looking for.</p>
          </div>
        </section>
      </Layout>
    )
  );
};

export default NotFoundPage;
